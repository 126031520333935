<template>
  <div v-if="chartdata  && chartdata.datasets.length">
    <div class="mb-3">
      <h4>Article Statistic</h4>
      <h5>
        <a :href="form.url" target="_blank">
          {{form.title}}
        </a>
      </h5>
    </div>
    <div class="row mb-5">
      <div class="col-md-2 col-xs-1">
        <select name="" id="" @change="dateChange($event)" class="form-control">
          <option v-for="item in dateFilter" :key="item.id" :value="item.id">
            {{item.name}}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 col-xs-1" v-if="!statLoading">
        <div class="card text-left">
          <div class="card-body">
            <h4 class="card-title">Total Views</h4>
            <h3 class="card-text">{{viewStats.count}}</h3>
            <div>
              <span >
                Min: {{viewStats.statistic.min[0] | moment('D MMM YYYY')}} ({{viewStats.statistic.min[1]}})
                <!-- min: {{moment(viewStats.statistic.min[0]).format('DD MMM YYYY')}} ({{viewStats.statistic.min[1]}}) -->
              </span>
            </div>
            <div>
              <span>
                Max: {{viewStats.statistic.max[0] | moment('D MMM YYYY')}} ({{viewStats.statistic.max[1]}})
                <!-- max: {{moment(viewStats.statistic.max[0]).format('DD MMM YYYY')}} ({{viewStats.statistic.max[1]}}) -->
              </span>
            </div>
            <div>
              <span>
                Average visitor per day: {{Math.round(viewStats.statistic.avg)}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 col-md-10 col-xs-10" v-if="!statLoading">
        <BarChart :chart-data="chartdata" :options="options"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xs-12" v-if="!statLoading">
        <div class="card text-left">
          <div class="card-body">
            <h4 class="card-title">Country</h4>
            <div class="card-text pl-3">
              <div class="row pl-3" v-if="viewStats.flagData && viewStats.flagData.length">
                <div class="col-xs-1-8 mr-3 " >
                  <div class="row" v-for="(country,i) in viewStats.flagData" :key="i">
                    <div class="col-xs-1-12 mr-3 " >
                      <a-tooltip placement="bottom" >
                        <div>
                          <h5>{{country.flag}} ({{country.count}}):</h5>
                          <span>{{country.cities.map(m => `${m.city ? m.city : ' other '}(${m.count})`).join(', ')}}
                          </span>
                        </div>
                        <template slot="title">
                          <span>{{country.code}}</span></template>
                      </a-tooltip>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3" v-if="postData.reposts.length">
      <div class="col-md-12 col-xs-12">
        <div class="card text-left">
          <div class="card-body">
            <h4 class="card-title ">Repost ({{postData.reposts && postData.reposts.length ? postData.reposts.length : 0}})</h4>
              <template v-for="(post, i) in postData.reposts">
                <div class="card" :key="i">
                  <div class="card-body">
                    <vue-moments-ago prefix="" :date="post.published_at" lang="en"></vue-moments-ago>
                    <!-- <small>{{ post.published_at | moment}}</small> -->
                    <!-- <small>{{ moment(post.published_at).fromNow()}}</small> -->
                    <h4 class="card-title">{{post.title}}</h4>
                    <p class="card-text">{{post.excerpt}}</p>
                  </div>
                </div>
              </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BarChart from './bar-chart.vue'
import moment from 'moment'
import VueMomentsAgo from 'vue-moments-ago'
export default {
  components: {
    BarChart,
    'vue-moments-ago': VueMomentsAgo,
  },
  props: {
    postId: {
      type: String,
    },
  },
  name: 'postStat',
  data() {
    return {
      maxCity: 20,
      id: this.postId,
      statLoading: false,
      portal_id: null,
      postData: null,
      dateFilter: [
        {
          id: 0,
          name: 'All period',
          start: null,
          end: null,
        },
        {
          id: 1,
          name: 'This week',
          start: moment(Date.now()).subtract('0', 'weeks').startOf('isoweek').format('YYYY-MM-DD'),
          end: moment(Date.now()).subtract('0', 'weeks').endOf('isoweek').format('YYYY-MM-DD'),
        },
        {
          id: 2,
          name: 'Last week',
          start: moment(Date.now()).subtract('1', 'weeks').startOf('isoweek').format('YYYY-MM-DD'),
          end: moment(Date.now()).subtract('1', 'weeks').endOf('isoweek').format('YYYY-MM-DD'),
        },
        {
          id: 3,
          name: 'This month',
          start: moment(Date.now()).subtract('0', 'month').startOf('month').format('YYYY-MM-DD'),
          end: moment(Date.now()).subtract('0', 'month').endOf('month').format('YYYY-MM-DD'),
        },
        {
          id: 4,
          name: 'This 3 months',
          start: moment(Date.now()).subtract('2', 'month').startOf('month').format('YYYY-MM-DD'),
          end: moment(Date.now()).subtract('0', 'month').endOf('month').format('YYYY-MM-DD'),
        },
        {
          id: 5,
          name: 'This 6 month',
          start: moment(Date.now()).subtract('5', 'month').startOf('month').format('YYYY-MM-DD'),
          end: moment(Date.now()).subtract('0', 'month').endOf('month').format('YYYY-MM-DD'),
        },
        {
          id: 6,
          name: 'This year',
          start: moment(Date.now()).subtract('0', 'year').startOf('year').format('YYYY-MM-DD'),
          end: moment(Date.now()).subtract('0', 'year').endOf('year').format('YYYY-MM-DD'),
        },
        {
          id: 7,
          name: 'Last year',
          start: moment(Date.now()).subtract('1', 'year').startOf('year').format('YYYY-MM-DD'),
          end: moment(Date.now()).subtract('1', 'year').endOf('year').format('YYYY-MM-DD'),
        },
      ],
      filter: {},
      detail: {},
      chartdata: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {

          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                // stepSize: 5,
              },
            },
          ],
          xAxes: [
            {
              offset: true,
              // gridLines: {
              //   offsetGridLines: false,
              // },
              // ticks: {
              //   maxRotation: 90,
              //   minRotation: 80,
              // },
              // stacked: true,
              type: 'time',
              time: {
                tooltipFormat: 'MM/DD/YYYY',
                unit: 'day',
                displayFormats: {
                  day: 'MMM D',
                },
              },
            },
            // {
            //   position: 'top',
            //   ticks: {
            //     maxRotation: 90,
            //     minRotation: 80,
            //   },
            //   gridLines: {
            //     offsetGridLines: true,
            //   },
            // },
          ],
        },
      },
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
    ...mapState('post', {
      form: (state) => state.form,
      viewStats: (state) => state.viewStats,
      // statLoading: (state) => state.loading,
    }),
    ...mapState('category', {
      categories: (state) => state.data,
      categoryTree: (state) => state.dataTree,
    }),
    ...mapState('tag', {
      tags: (state) => state.data,
      tagLoading: (state) => state.loading,
      tagsForm: (state) => state.form,
    }),
    loading() {
      return this.$store.state.post.loading
    },
  },
  methods: {
    dateChange(e) {
      const vm = this
      this.statLoading = true
      vm.chartdata.labels = []
      vm.chartdata.data = []
      let arrDateFilter = []
      arrDateFilter = JSON.parse(JSON.stringify(vm.dateFilter))
      const filter = arrDateFilter.find(f => f.id === parseInt(e.target.value))
      this.filter = filter
      this.fetchs()
    },
    fetchs() {
      const vm = this
      if (vm.$route.params && vm.$route.params.id) {
        vm.id = vm.$route.params.id
      }
      //
      vm.$store.dispatch('post/VIEWSTAT_FETCH', {
        id: vm.id,
        start: vm.filter && vm.filter.start ? vm.filter.start : moment(vm.form.published_at).format('YYYY-MM-DD'),
        end: vm.filter && vm.filter.end ? vm.filter.end : moment().format('YYYY-MM-DD'),
        portal_id: vm.form.portals[0].id,
      }).then(() => {
        this.statLoading = false
        const dates = vm.$store.state.post.viewStats.data.map(m => m[0])
        const data = vm.$store.state.post.viewStats.data.map(m => m[1])
        vm.chartdata = {
          labels: dates,
          datasets: [
            {
              // barPercentage: 1,
              // barThickness: 50,
              maxBarThickness: 100,
              // minBarLength: 2,
              label: 'Views',
              backgroundColor: '#f87979',
              data: data,
            },
          ],
        }
        this.$nprogress.done()
      }).catch(() => this.$nprogress.done())
    },
  },
  destroyed() {
    this.$store.commit('post/CLEAR_FORM')
    this.$store.commit('post/CLEAR_VIEWSTATS')
  },
  created() {
    this.$nprogress.start()
    this.$store.commit('post/CLEAR_FORM')
    this.$store.commit('post/CLEAR_VIEWSTATS')
    const vm = this
    vm.filter = vm.dateFilter[0]
    vm.$store.dispatch('post/FETCH_ONE', vm.id).then(post => {
      vm.portal_id = post.data.portals[0].id
      vm.postData = post.data
      vm.fetchs()
    })
  },
  watch: {
    postId: function (postId) {
      this.$nprogress.start()
      this.$store.commit('post/CLEAR_FORM')
      this.$store.commit('post/CLEAR_VIEWSTATS')
      const vm = this
      vm.id = postId
      vm.$store.dispatch('post/FETCH_ONE', vm.id).then(post => {
        vm.portal_id = post.data.portals[0].id
        vm.postData = post.data
        vm.fetchs()
      })
    },
  },
}
</script>
